<template>
  <div class="dialog-container weekBox">
    <el-dialog
      title="损耗类型管理"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="700px"
    >
      <el-table :data="tableData" center>
        <el-table-column prop="name" label="名称">
          <template slot-scope="scope">
            <el-input v-model="scope.row.name"></el-input>
          </template>
        </el-table-column>

        <el-table-column prop="sort" label="序号">
          <template slot-scope="scope">
            <el-input v-model="scope.row.sort" type="number" oninput="if(value>10000)value=10000"></el-input>
          </template>
        </el-table-column>

        <el-table-column prop label="操作">
          <template slot-scope="scope">
            <i
              v-if="1 !== tableData.length"
              class="el-icon-remove-outline"
              @click="removeItem(scope.$index)"
            ></i>
            <i v-if="0 == scope.$index" class="el-icon-circle-plus-outline" @click="pushEmptyItem"></i>
          </template>
        </el-table-column>
      </el-table>

      <template slot="footer">
        <el-button class="footer-confirm" @click="sureEvent">{{$t('sure')}}</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button class="footer-cancel" @click="closeDialog">{{$t('cancel')}}</el-button>

        <!-- <el-button class="footer-confirm" v-if="!isEdit" @click="closeDialog">关闭</el-button> -->
      </template>
    </el-dialog>
  </div>
</template>

<script>
// 损耗类型管理的弹窗
import mixin from "@/mixins/dialogMixin";
import apis from "@/apis";
import { get } from "lodash";

export default {
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      tableData: [],
      deleteItems: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.deleteItems = [];
      this.$http.post(apis.brokenTypeList).then((res) => {
        // console.log(res.data.rows)
        this.tableData = get(res, "data.rows", []);

        if (this.tableData.length === 0) {
          this.pushEmptyItem();
        }
      });
    },

    async sureEvent() {
      // 新增 && 保存
      const loadingInstance = this.$loading();
      // eslint-disable-next-line guard-for-in
      console.log("+++++this.tableData:", this.tableData);
      // eslint-disable-next-line guard-for-in
      for (const i in this.tableData) {
        const item = this.tableData[i];

        if (!item.name && !item.sort) {
          // eslint-disable-next-line no-continue
          continue;
        }
        let request;

        if (item.id) {
          request = this.$http.put(apis.brokenType, item);
        } else {
          //  新增
          request = this.$http.post(apis.brokenType, {
            name: item.name,
            sort: item.sort,
          });
        }

        // eslint-disable-next-line no-await-in-loop
        await request;
      }

      // 删除
      // eslint-disable-next-line guard-for-in
      for (const i in this.deleteItems) {
        const item = this.deleteItems[i];
        // eslint-disable-next-line no-await-in-loop
        await this.$http.delete(apis.brokenType, {
          params: {
            id: item.id,
          },
        });
      }
      loadingInstance.close();
      this.closeDialog();
      this.$message.success('操作成功');
    },

    removeItem(index) {
      //   console.log(index);
      const item = this.tableData[index];
      this.deleteItems.push(item);
      this.tableData.splice(index, 1);
    },
    pushEmptyItem() {
      this.tableData.push({
        name: "",
        sort: "",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-icon-remove-outline,
.el-icon-circle-plus-outline {
  font-size: 24px;
  cursor: pointer;
}

.el-icon-remove-outline {
  margin-right: 16px;
  color: #f67979;
}
.el-icon-circle-plus-outline {
  color: rgb(141, 207, 92);
}
</style>
