<template>
  <div class="wrap">
    <searchForm :form.sync="form" @search="searchEvent"></searchForm>

    <div class="table-container">
      <el-table
        :data="tableData"
        :height="tableHeight - 20"
        style="width: 100%"
        border
        @sort-change="sort_post_change"
        :row-class-name="tableRowClassName"
      >
        <el-table-column prop="deviceCode" align="center" min-width="110" label="设备编码"></el-table-column>
        <el-table-column prop="brokenType" align="center" min-width="110" label="损耗情况"></el-table-column>
        <el-table-column prop="reportUser" align="center" min-width="110" label="上报人"></el-table-column>
        <el-table-column prop="mobile" align="center" min-width="110" label="联系电话"></el-table-column>
        <el-table-column prop="reportTime" align="center" min-width="110" label="上报时间"></el-table-column>
        <el-table-column prop="state" align="center" min-width="110" label="状态">
          <template slot-scope="scope">
            {{1 === scope.row.state? '已处理': '待处理' }}
          </template>
        </el-table-column>
        <el-table-column prop="solution" align="center" min-width="110" label="处理办法"></el-table-column>
        <el-table-column prop="" align="center" min-width="180" label="操作">
          <template slot-scope="scope">
            <div>
              <el-button class="default-button" @click="handleItem(scope.row.id)">处理</el-button>
              <el-button class="default-button" @click="viewDetail(scope.row.id)">查看详情</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pageination-wrapper">
      <div>
        <el-button class="default-button" @click="isShowDamageTypeManage=true">损耗类型管理</el-button>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="form.total"
      ></el-pagination>
    </div>
    <add-dialog ref="addDialog" :Language="Language" :show="addDialog.visible" :isEdit="addDialog.isEdit" :isDisabled="addDialog.isDisabled" @closeDialog="closeDialog" @afterSuccess="afterSuccess"></add-dialog>
    <!-- <handleDialog :show.sync="handleDialogVisible"  @afterSuccess="afterSuccess" :id="editingId" :isEdit="true"></handleDialog> -->
    <!-- <handleDialog :show.sync="detailDialogVisible" :id="editingId" :isEdit="false"></handleDialog> -->
    <damageTypeManage :show.sync="isShowDamageTypeManage"></damageTypeManage>
  </div>
</template>

<script>
import { mapState } from "vuex";
import mixin from "@/mixins/index";
import apis from "@/apis";
import searchForm from "../components/searchForm.vue";
// import handleDialog from "./dialog/handleDialog.vue";
import damageTypeManage from './dialog/damageTypeManage.vue';
import addDialog from "./dialog/addDialog.vue";

class ContentClassification {}

export default {
  mixins: [mixin],
  components: {
    addDialog,
    searchForm,
    // handleDialog,
    damageTypeManage,
  },
  data() {
    return {
      form: new ContentClassification("form"),
      tableData: [],
      handleDialogVisible: false,
      detailDialogVisible: false,
      isShowDamageTypeManage: false,
      editingId: '',
      addDialog: {
        visible: false,
        isEdit: false,
        isDisabled: false,
      },
    };
  },
  props: {},
  watch: {},
  computed: {
    ...mapState(["Language", "DROPDOWNBOX"]),
  },
  methods: {
    /**
     * @function 搜索方法
     */
    searchData() {
      this.$http.post(apis.maintenanceReportList, this.form).then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.rows;
          this.form.total = res.data.total;
        }
      });
    },

    /**
     * 处理
     */
    handleItem(id) {
      this.addDialog.visible = true;
      this.addDialog.isEdit = true;
      this.addDialog.isDisabled = true;
      this.$refs["addDialog"].getDataQuery(id);
    },

    /**
     * 查看详情
     */
    viewDetail(id) {
      this.addDialog.visible = true;
      this.addDialog.isEdit = true;
      this.addDialog.isDisabled = false;
      this.$refs["addDialog"].getDataQuery(id);
    },

    /**
     * 关闭弹框
     */
    closeDialog() {
      this.addDialog.visible = false;
      this.addDialog.isEdit = false;
    },

    /**
     * 保存或修改成功回调刷新
     */
    afterSuccess() {
      this.searchData();
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="less" scoped>
@import "~@/assets/styles/mixin.scoped.less";
.search-wrapper {
  width: 100%;
}
.pageination-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
}
.img_text {
  display: flex;
  align-items: center;
}
</style>
<style lang="less">
@import "~@/assets/styles/mixin.less";
</style>
