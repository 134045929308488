/* eslint-disable */
let ak = 'uOONS3ZiGapapMDDpXdFGcYNfxHglkYx';

let promise = new Promise(function(resolve, reject) {
  if (typeof window.BMap !== 'undefined') {
    resolve(window.BMap)
    return true
  }
  window.onBMapCallback = function() {
    resolve(window.BMap)
  }
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.src =
    'https://api.map.baidu.com/api?v=3.0&ak=' + ak + '&callback=onBMapCallback'
  script.onerror = reject
  document.head.appendChild(script)
})

export default function loadBMap(ak) {
    return promise
  }
  