/*
 * @Author: your name
 * @Date: 2020-03-23 11:19:40
 * @LastEditTime: 2020-09-10 11:44:07
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\views\venueMana\venue\dialog\addDialogClass.js
 */
/* eslint-disable */
class searchFrom {
    constructor(type) {
      if (type === 'form') {
        // this.solution = ''
      } else if (type === 'rule') {
        // this.solution = [{ required: true, message: '请输入处理方式', trigger: ['change', 'blur'] }]
      }
    }
  }
  export default searchFrom
  