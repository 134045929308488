<template>
  <div class="dialog-container convenienceDialog">
    <el-dialog
      title="器材维修处理"
      :visible.sync="show"
      @opened="openDialog"
      :before-close="closeDialog"
      top="10px"
      center
      :close-on-click-modal="false"
      width="700px"
    >
      <el-form ref="form" :model="form" label-width="120px" :rules="rules">
        <el-row>
          <el-col :span="12">
            <el-form-item label="设备编码" prop="deviceCode">{{ form.deviceCode }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="损耗类型" prop="brokenType">{{ form.brokenType }}</el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="上报人" prop="reportUser">{{ form.reportUser }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话" prop="mobile">{{ form.mobile }}</el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="地图标注" prop="point">
              <div class="bmap-wrap">
                <new-map  v-if="showMap" ref="bMap" :address.sync="form.mapAnnotation" :point.sync="form.point" :isEdit="isEdit" @getPoint="firstGet" @onMapAdress="getRAdress" height="300px"></new-map>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="损坏信息">
              <el-image
                v-for="(img, index) in form.images"
                :key="index"
                class="damage-info"
                :src="img"
                :preview-src-list="[img]"
                fit="cover"
              ></el-image>

              <video
                v-for="(video, index) in form.videos"
                :key="index"
                :src="video"
                class="damage-info"
                controls
              ></video>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注" prop="remark">{{ form.remark }}</el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="处理方式" prop="solution">
              <el-input type="textarea" clearable v-model="form.solution" :disabled="!isDisabled"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template slot="footer">
        <el-button class="footer-confirm" v-if="isDisabled" @click="sureEvent">{{$t('sure')}}</el-button>
        <el-button class="footer-cancel" v-if="isDisabled" @click="closeDialog">{{$t('cancel')}}</el-button>
        <el-button class="footer-confirm" v-if="!isDisabled" @click="closeDialog">关闭</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import mixin from "@/mixins/dialogMixin";
import AddDialogClass from "./addDialogClass";
import apis from "@/apis";
import newMap from "./bMap.vue";

export default {
  mixins: [mixin],
  components: {
    newMap,
  },
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    isEdit: {
      required: true,
      type: Boolean,
    },
    isDisabled: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      baseImage: "",
      form: new AddDialogClass("form"), // 实例化一个表单的变量
      rules: new AddDialogClass("rule", this.Language), // 实例化一个表单的规则
      apis,
      showMap: true,
      isRequired: true,
      id: "",
    };
  },
  computed: {
    ...mapState(["DROPDOWNBOX", "TOKEN", "venueList"]),
  },
  methods: {
    getRAdress(address, point) {
      console.log('getRAdress-add', address)
      this.form.mapAnnotation = address.address;
      this.form.point.lat = point.lat;
      this.form.latitude = point.lat;
      this.form.point.lng = point.lng;
      this.form.longitude = point.lng;
    },
    /**
     * @function 关闭弹窗
     * @description 先初始化，然后传值到组件外部进行关闭
     **/
    async closeDialog() {
      await this.init()
      this.$refs.bMap.clearData();
      this.$emit('closeDialog')
    },
    ...mapMutations(['setVenueId', "setVenueList"]),
    /**
     * @function 初始化弹窗
     */
    init() {
      this.$refs.form.resetFields();
      this.form = new AddDialogClass("form");
      console.log("我初始化了");
    },
    firstGet(point, address) {
      console.log('getfist')
      this.form.point.lat = point.lat;
      this.form.point.lng = point.lng;
      this.form.mapAnnotation = address;
    },
    // 地图选点成功返回
    onGetPoint(point, address) {
      this.form.point.lat = point.lat;
      this.form.point.lng = point.lng;
      this.form.mapAnnotation = address;
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      // 这里写业务需求
      // 这里写业务需求
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$http.put(apis.maintenanceReport, {
            id: this.id,
            solution: this.form.solution,
          }).then((res) => {
            if (res.data.code === 0) {
              this.closeDialog();
              this.$emit("afterSuccess");
            }
          });
        }
      });
    },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getDataQuery(id) {
      this.id = id;
      // 在这里获取回显的数据
      this.$http.get(`${apis.maintenanceReport}/${id}`).then((res) => {
        if (res.data.code === 0) {
          this.form = { ...this.form, ...res.data.data };
          this.form.point = {
            lng: Number(res.data.data.longitude),
            lat: Number(res.data.data.latitude),
          }
          console.log("+++++++this.form", this.form);
          if (this.form.pictureUrl) {
            this.form.images = [this.form.pictureUrl];
          }

          if (this.form.videoUrl) {
            this.form.videos = [this.form.videoUrl];
          }
          this.showMap = false
          this.$nextTick(() => {
            this.showMap = true
          })
        }
      });
    },
    openDialog() {
      if (!this.isEdit) {
        this.showMap = false
        this.$nextTick(() => {
          this.showMap = true
        })
      }
    },
    updatePoint(point) {
      this.form.point = point
      this.showMap = false
      this.$nextTick(() => {
        this.showMap = true
      })
      console.log(point)
    },
  },
};
</script>
<style lang="less" scoped>
.image-upload {
  width: 50px;
  height: 50px;
}
.upload-images-tip {
  color:rgba(168,168,168,1);
  font-size:12px;
  margin-top: -15px;
}
.bmap-wrap {
  width: 100%;
}
.image-wrap{
  width: 50px;
  height: 50px;
  margin-right: 10px;
  position: relative;
  float: left;
  .images-close {
    position: absolute;
    right: -7px;
    top: -7px;
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
}
.damage-info {
  width: 150px;
  height: 150px;
}
</style>
